import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ProjectDateRangeRequest } from 'src/app/common/components/filters/date-range/date-range.model'

import { ListRequest } from '~common/types/api'
import { CategoryMatrix, PaginatedData } from '~core/models'
import { JsonResponse } from '~core/models/json-response.model'
import { ApiService } from '~core/services'
import { CommentTopOccurrences } from '~features/comments/types'

import {
  Insight,
  InsightsOverview,
  InsightsOverviewLegacy,
  InsightsRequest,
  Post,
  PostsRequest,
  Reel,
  Video,
  VideosRequest,
} from '../models'

@Injectable({
  providedIn: 'root',
})
export class FacebookPageApiService {
  #defaultVersion = 'v1.1'
  private _prefix = '/{version}/projects/{projectId}/facebook-page'

  constructor(private apiService: ApiService) {}

  insights({ projectId, fields, ...range }: InsightsRequest): Observable<JsonResponse<Insight[]>> {
    const params = new HttpParams({ fromObject: { ...range, fields: fields.join(',') } })
    return this.apiService.get(`${this.prefix(projectId)}/insights`, params)
  }

  insightsOverview({ projectId, ...range }: ProjectDateRangeRequest): Observable<InsightsOverview> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/overview`, params)
  }

  onlineFansPerHour({ projectId, ...range }: ProjectDateRangeRequest): Observable<JsonResponse<CategoryMatrix>> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId)}/online-fans-per-hour`, params)
  }

  post({ projectId, postId }: { projectId: string; postId: string }): Observable<JsonResponse<Post>> {
    return this.apiService.get(`${this.prefix(projectId)}/posts/${postId}`)
  }

  posts(projectId: string, query: ListRequest): Observable<PaginatedData<Post>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/posts`, params)
  }

  reel({ projectId, reelId }: { projectId: string; reelId: string }): Observable<JsonResponse<Reel>> {
    return this.apiService.get(`${this.prefix(projectId)}/reels/${reelId}`)
  }

  reels(projectId: string, query: ListRequest): Observable<PaginatedData<Reel>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/reels`, params)
  }

  topCommentOccurrences({
    projectId,
    ...range
  }: ProjectDateRangeRequest): Observable<JsonResponse<CommentTopOccurrences>> {
    const params = new HttpParams({ fromObject: { ...range } })
    return this.apiService.get(`${this.prefix(projectId)}/top-comment-occurrences`, params)
  }

  video({ projectId, videoId }: { projectId: string; videoId: string }): Observable<JsonResponse<Video>> {
    return this.apiService.get(`${this.prefix(projectId)}/videos/${videoId}`)
  }

  videos(projectId: string, query: ListRequest): Observable<PaginatedData<Video>> {
    const params = new HttpParams({ fromObject: { ...query } })
    return this.apiService.get(`${this.prefix(projectId, 'v1.2')}/videos`, params)
  }

  private prefix(projectId: string, version: string = this.#defaultVersion): string {
    return this._prefix.replace('{projectId}', projectId).replace('{version}', version)
  }
}
